import { Component, Vue } from "vue-property-decorator";

import { Header ,Footer} from "@/layout";
import { APLightbox, APConfirmDelete } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { QuizModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
    question = '';
    tid = '';
    difficulty = '';
    grade = '';
    level = '';
    unit = '';
    active = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherQuizQuestionController>({
    components: {
        Header, APLightbox, APConfirmDelete,Footer
    }
})
export default class ApTeacherQuizQuestionController extends Vue {
    private doubleClick = false;
    private searchForm = new SearchForm();
    private teacherList: object = {};
    private questionList: object = {};
    private gradeList: object = {};
    private levelList: object = {};
    private unitList: response = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private qqid = 0;
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    public async created() {
        this.getTeacher();
        this.getQuestionList();
        this.gradeList = await TeCommon.getGrade();
    }

    // 列出可選老師
    private async getTeacher() {
        const item: { [key: string]: string } = {
            token: this.token,
        }
        const data = await QuizModel.teGetAllTeacher(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.teacherList = data.list as object;
    }

    public async getQuestionList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            question: this.searchForm.question,
            tid: this.searchForm.tid,
            difficulty: this.searchForm.difficulty,
            grade: this.searchForm.grade,
            level: this.searchForm.level,
            unit: this.searchForm.unit,
            active: this.searchForm.active,
            token: this.token,
        }
        const data = await QuizModel.teGetQuestionList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.questionList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getQuestionList();
    }

    private async getLevel() {
        this.searchForm.level = '';
        this.searchForm.unit = '';
        const grade = this.searchForm.grade;
        this.levelList = await TeCommon.getLevel(grade);
        this.unitList = {};
    }

    private async getUnit() {
        this.searchForm.unit = '';
        this.unitList = await TeCommon.getUnit();
    }

    private delQuestion(id: number) {
        this.$emit("openConfirmDelete", true);
        this.qqid = id;
    }

    private async deleteQuestion() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const item = {
                qqid: this.qqid,
                token: this.token,
            }
            const errNo = await QuizModel.teDelQuestion(item);
            this.$emit("closeConfirmDelete", false);
            switch (errNo) {
                case ErrorCode.InvalidToken:
                    TeCommon.logout();
                    break;
                case ErrorCode.Success:
                    this.doubleClick = false;
                    this.$router.go(0); //reload page
                    break;
                default:
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    this.doubleClick = false;
                    break;
            }
        }
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}